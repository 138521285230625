/**
 * Nft Collection
 * @author Raul
 */
import {IResource} from '@simpli/resource-collection'
import {Contract} from './Contract'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {Dapp} from './Dapp'
import {FilterHelper} from '@/helpers/FilterHelper'

export class NftCollection implements IResource {
  idNftCollectionPk: number = 0
  idContractFk: number | null = null

  @ResponseSerialize(Contract)
  contract: Contract | null = null

  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  name: string | null = null
  slug: string | null = null
  uuid: string | null = null
  hash: string | null = null
  ownerAddress: string | null = null
  imageUrl: string | null = null
  averagePriceWeek: number | null = null
  weekPriceChange: number | null = null
  lowestPrice: number | null = null
  highestPrice: number | null = null
  totalSupply: number | null = null
  monthVolume: number | null = null
  monthVolumeChange: number | null = null
  dataFetchedDatetime: Date | null = null

  get blockchainTagName() {
    // TODO: Implement dynamic logic
    return 'N3'
  }

  get $id() {
    return this.idNftCollectionPk
  }

  set $id(val) {
    this.idNftCollectionPk = val
  }

  get $tag() {
    return `${this.name}`
  }

  get averagePriceWeekFormatted() {
    return FilterHelper.currency(this.averagePriceWeek)
  }

  get lowestPriceFormatted() {
    return FilterHelper.currency(this.lowestPrice)
  }

  get monthVolumePriceFormatted() {
    return FilterHelper.currency(this.monthVolume)
  }

  get totalSupplyFormatted() {
    return Number(this.totalSupply).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  /**
   * Persists a new instance of Nft Collection. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persist() {
    return await Request.post(`/client/nft-collection`, this)
      .name('persistNftCollection')
      .asNumber()
      .getData()
  }
}
